/* eslint-disable import/prefer-default-export */
const setResponsiveTable = (table: Element): void => {
  const head = table.querySelector('thead');
  const body = table.querySelector('tbody');
  const foot = table.querySelector('tfoot');

  if (head) {
    const headers: string[] = [];
    const ths = head.querySelectorAll('th');
    ths.forEach((h) => {
      if (!h.classList.contains('table__cell--self-explanatory')) {
        headers.push(h.innerHTML); // Because innerText inherits stuff like text-transform
      } else {
        headers.push(''); // If header is self explanatory we do not add it
      }
    });

    const rowContainers: HTMLTableSectionElement[] = [];
    // Table elements that possibly contains <tr>'s
    if (body) {
      rowContainers.push(body);
    }
    if (foot) {
      rowContainers.push(foot);
    }

    rowContainers.forEach((rc) => {
      const rows = rc.querySelectorAll('tr');
      rows.forEach((tr) => {
        const cells = tr.querySelectorAll('td, th');
        cells.forEach((cell, i) => {
          if (headers[i]) {
            // if header is self explanatory we skip (value of array index is '' which is falsy)
            cell.setAttribute('data-label', headers[i]);
          }
        });
      });
    });
  }
};

export {
  setResponsiveTable,
};
