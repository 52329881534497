/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-env browser */
/* eslint-disable import/prefer-default-export */
/* eslint-disable no-param-reassign */
import { getBaseFontSize } from './helpers';

const getMediaWidthBasedOnModifier = (classList: DOMTokenList): number => {
  if (classList.contains('tabbed-content--hide-tabs-on-medium-width')) {
    return parseInt(getComputedStyle(document.documentElement).getPropertyValue('--bp-medium').replace('rem', ''), 10);
  }
  if (classList.contains('tabbed-content--hide-tabs-on-wide-width')) {
    return parseInt(getComputedStyle(document.documentElement).getPropertyValue('--bp-wide').replace('rem', ''), 10);
  }

  return -1;
};

const isBelowViewportLimit = (classList: DOMTokenList): boolean => {
  const viewportWidth = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
  const widthInRem = viewportWidth / getBaseFontSize();
  const mediaWidth = getMediaWidthBasedOnModifier(classList);
  if (mediaWidth > -1) {
    return widthInRem < mediaWidth;
  }
  return true;
};

const destroyTabs = (tabbed: Element): void => {
  const tablist = tabbed.querySelector('.nav__items');
  const tabs = tablist?.querySelectorAll('a');
  const panels: HTMLElement[] = [];
  const sections = tabbed.querySelectorAll('section');
  sections.forEach((section) => {
    if (section.parentNode === tabbed) {
      panels.push(section);
    }
  });
  tabs?.forEach((tab) => {
    tab.removeAttribute('aria-selected');
    tab.classList.remove('nav__item--active');
  });

  panels.forEach((panel) => {
    panel.hidden = false;
    panel.removeAttribute('role');
  });
};

const switchTab = (
  tabs: NodeListOf<HTMLAnchorElement>,
  panels: HTMLElement[],
  oldTab: Element | null,
  newTab: HTMLElement,
  _: DOMTokenList,
  focus = true,
  // eslint-disable-next-line no-console
  callback = (index: number): void => console.log(index),
): void => {
  if (focus) {
    newTab.focus();
  }
  // Make the active tab focusable by the user (Tab key)
  newTab.removeAttribute('tabindex');
  // Set the selected state
  newTab.setAttribute('aria-selected', 'true');
  newTab.classList.add('nav__item--active');
  if (oldTab) {
    oldTab.removeAttribute('aria-selected');
    oldTab.setAttribute('tabindex', '-1');
    oldTab.classList.remove('nav__item--active');
  }

  const index = Array.prototype.indexOf.call(tabs, newTab);

  // Get the indices of the new and old tabs to find the correct
  // tab panels to show and hide
  panels[index].hidden = false;
  if (oldTab) {
    const oldIndex = Array.prototype.indexOf.call(tabs, oldTab);
    panels[oldIndex].hidden = true;
  }

  // Dispatches custom event on every tab change
  newTab.dispatchEvent(new CustomEvent('switch-tab', {
    bubbles: true,
    detail: { tab: index },
  }));
  callback(index);
};

const setTabbedContent = (
  key: number,
  tabbed: Element,
  initial = 0,
  focus = true,
  // eslint-disable-next-line no-console
  callback = (index: number) => console.log(index),
): void => {
  const tablist = tabbed.querySelector('.nav__items') as HTMLElement;
  const tabs = tablist.querySelectorAll('a');
  const panels: HTMLElement[] = [];
  const sections = tabbed.querySelectorAll('section');
  sections.forEach((section) => {
    if (section.parentNode === tabbed) {
      panels.push(section);
    }
  });

  const expandAll = tabbed.querySelector('button.tabbed-content__expand-all');
  if (expandAll) {
    const expandAllEvent = () => {
      tablist.hidden = true;
      panels.forEach((panel: HTMLElement) => {
        panel.hidden = false;
      });
    };

    expandAll.removeEventListener('click', expandAllEvent);
    expandAll.addEventListener('click', expandAllEvent);
  }

  // Add the tablist role to the first <ul> in the .tabbed container
  tablist.setAttribute('role', 'tablist');

  // Add semantics are remove user focusability for each tab
  tabs.forEach((tab, i) => {
    tab.setAttribute('role', 'tab');
    tab.setAttribute('id', `tab${key}_${(i + 1)}`);
    tab.setAttribute('tabindex', '-1');
    tab.parentElement?.setAttribute('role', 'presentation');

    // Handle clicking of tabs for mouse users
    const tabClickEvent = (e: MouseEvent) => {
      e.preventDefault();
      const currentTab = tablist.querySelector('[aria-selected]');
      if (e.currentTarget !== currentTab) {
        // eslint-disable-next-line max-len
        switchTab(tabs, panels, currentTab, e.currentTarget as HTMLElement, tabbed.classList, true, (tabIndex) => {
          callback(tabIndex);
        });
      }
    };

    tab.removeEventListener('click', tabClickEvent);
    tab.addEventListener('click', tabClickEvent);

    // Handle keydown events for keyboard users
    const tabKeyEvent = (e: KeyboardEvent) => {
      // Get the index of the current tab in the tabs node list
      const index = Array.prototype.indexOf.call(tabs, e.currentTarget);
      // Work out which key the user is pressing and
      // Calculate the new tab's index where appropriate
      let dir: number | string | null = null;
      if (e.key === 'ArrowLeft') {
        dir = index - 1;
      }
      if (e.key === 'ArrowRight') {
        dir = index + 1;
      }
      if (e.key === 'ArrowDown') {
        dir = 'down';
      }
      if (dir !== null) {
        e.preventDefault();
        // If the down key is pressed, move focus to the open panel,
        // otherwise switch to the adjacent tab
        if (dir === 'down') {
          panels[i].focus();
        } else if (tabs[dir]) {
          // eslint-disable-next-line max-len
          switchTab(tabs, panels, e.currentTarget as Element, tabs[dir], tabbed.classList, true, (tabIndex) => {
            callback(tabIndex);
          });
        }
      }
    };
    tab.removeEventListener('keydown', tabKeyEvent);
    tab.addEventListener('keydown', tabKeyEvent);
  });

  // Add tab panel semantics and hide them all
  panels.forEach((panel: HTMLElement, i: number) => {
    panel.setAttribute('tabindex', '-1');
    panel.setAttribute('aria-labelledby', tabs[i].id);
    // eslint-disable-next-line no-param-reassign
    panel.hidden = true;
    panel.setAttribute('role', 'tabpanel');
  });

  // Initially activate the first tab and reveal the first tab panel
  if (initial > -1) {
    switchTab(tabs, panels, null, tabs[initial], tabbed.classList, focus);
  }
};

export {
  setTabbedContent,
  isBelowViewportLimit,
  destroyTabs,
};
