/* eslint-env browser */
/* eslint-disable import/prefer-default-export */
const setContentToggle = (content: Element): Element | null => {
  const setContentClose = (button: HTMLElement, contentClose: HTMLElement, focus: boolean) => {
    if (contentClose) {
      if (focus) {
        contentClose.focus();
      }
      const click = () => {
        content.setAttribute('hidden', 'hidden');
        button.removeAttribute('hidden');
        button.setAttribute('aria-expanded', 'false');
        button.focus();
      };
      contentClose.removeEventListener('click', click);
      contentClose.addEventListener('click', click);
    }
  };

  const button = content?.parentElement?.querySelector('.content-toggle__toggle') as HTMLElement;
  const contentClose = content.querySelector('.content-toggle__close') as HTMLElement;

  if (button) {
    button.addEventListener('click', () => {
      const ariaExpanded = button.getAttribute('aria-expanded');
      button.setAttribute('aria-expanded', ariaExpanded === 'false' ? 'true' : 'false');
      if (ariaExpanded === 'true') {
        content.setAttribute('hidden', 'hidden');
      } else {
        content.removeAttribute('hidden');
      }

      if (button.hasAttribute('aria-haspopup')) {
        button.setAttribute('hidden', 'hidden');
        setContentClose(button, contentClose, true);
      }
    });

    setContentClose(button, contentClose, false);
  }
  return button;
};

export {
  setContentToggle,
};
