/* eslint-env browser */
import { copyToClipboard, isElementWithClassNameInEventPath } from './helpers';

const findNav = (element: HTMLElement): HTMLElement => {
  const parent = element.parentElement;
  if (parent) {
    if (parent.classList.contains('nav')) {
      return parent;
    }
    return findNav(parent);
  }
  return element;
};

// eslint-disable-next-line max-len
const closeResponsiveNav = (navElements: NodeListOf<Element>, keepOpen: HTMLElement | null = null): void => {
  const navToggledClass = 'nav--toggled';
  navElements.forEach((n) => {
    const button = n.querySelector('.nav__toggle') as HTMLElement;
    if (n !== keepOpen) {
      if (n.classList.contains(navToggledClass)) {
        button.focus();
        button.setAttribute('aria-expanded', 'false');
      }
      n.classList.remove(navToggledClass);
    }
  });
};

const setResponsiveToggle = (navElements: NodeListOf<Element>): void => {
  const toggleMenu = (button: HTMLElement) => {
    const parent = findNav(button);

    const navItems = parent.querySelector('.nav__items');
    const lastNavItem = navItems?.querySelector('.nav__item:last-of-type');
    navItems?.classList.add('nav__items--fadeout');
    const firstElement = navItems?.querySelector('.nav__item') as HTMLElement;
    if (firstElement) {
      firstElement.focus();
    }

    const transitionListener = () => {
      parent.classList.toggle('nav--toggled');
      navItems?.classList.remove('nav__items--fadeout');
      lastNavItem?.removeEventListener('transitionend', transitionListener);
    };

    lastNavItem?.addEventListener('transitionend', transitionListener);
    return parent;
  };

  const toggle = document.querySelectorAll('.nav__toggle');
  toggle.forEach((button) => {
    button.addEventListener('click', () => {
      const nav = toggleMenu(button as HTMLElement);
      const ariaExpanded = button.getAttribute('aria-expanded');
      button.setAttribute('aria-expanded', ariaExpanded === 'false' ? 'true' : 'false');
      closeResponsiveNav(navElements, nav);
    });
  });
};

const setResponsiveNav = (navElements: NodeListOf<Element>, navElement: Element): void => {
  const navToggledClass = 'nav--toggled';
  const navItemsClass = 'nav__items';
  // gitlab #117
  navElement.classList.remove(navToggledClass);
  const navItemsParent = navElement.querySelector(`.${navItemsClass}`);

  if (navItemsParent) {
    const navItems = navItemsParent.querySelectorAll('.nav__item');
    const isOverflownClass = 'nav__item--is-overflown';
    const hasOverflowClass = 'nav--has-overflow';
    const isActiveClass = 'nav__item--active';
    let hasOverflown = false;

    for (let i = navItems.length - 1; i >= 0; i -= 1) {
      const nav = navItems[i] as HTMLElement;
      const top = nav.offsetTop;

      if (top > 0) {
        nav.classList.add(isOverflownClass);
        navElement.classList.add(hasOverflowClass);
        hasOverflown = true;
      } else if (!nav.classList.contains(isActiveClass)) {
        nav.classList.remove(isOverflownClass);
      }
    }

    if (!hasOverflown) {
      navElement.classList.remove(hasOverflowClass);
      const isActive = navElement.querySelector(`.${isActiveClass}`);
      if (isActive) {
        isActive.classList.remove(isOverflownClass);
      }
    }
  }

  // gitlab #115
  document.querySelector('body')?.addEventListener('click', (e) => {
    if (!(e.target as HTMLElement).classList.contains('nav__toggle')
      && !isElementWithClassNameInEventPath(e, navItemsClass)) {
      closeResponsiveNav(navElements);
    }
  });
};

const closeSubNav = (buttons: NodeListOf<Element>, keepOpen: Element | null = null) => {
  const subNavClass = 'sub-nav--toggled';
  buttons.forEach((button) => {
    if (button !== keepOpen) {
      if (button?.parentElement?.classList.contains(subNavClass)) {
        (button as HTMLElement).focus();
        button.setAttribute('aria-expanded', 'false');
      }
      button?.parentElement?.classList.remove(subNavClass);
    }
  });
};

const setSubNavToggle = (buttons: NodeListOf<Element>, button: Element): void => {
  const subNavClass = 'sub-nav--toggled';
  button.addEventListener('click', () => {
    button?.parentElement?.classList.toggle(subNavClass);
    if (button?.parentElement?.classList.contains(subNavClass)) {
      button.setAttribute('aria-expanded', 'true');
    } else {
      button.setAttribute('aria-expanded', 'false');
    }
    closeSubNav(buttons, button);
  });

  // gitlab #115
  document.querySelector('body')?.addEventListener('click', (e) => {
    if (!isElementWithClassNameInEventPath(e, 'sub-nav')) {
      closeSubNav(buttons);
    }
  });
};

const setCopyToClipboard = (lang = 'da'): void => {
  const copySpans = document.querySelectorAll('.copy-to-clipboard__this') as NodeListOf<HTMLElement>;
  copySpans.forEach((span) => {
    const parentBtn = span.parentNode as HTMLElement;
    const listener = () => {
      const toCopy = span.innerText;
      copyToClipboard(toCopy);
      // eslint-disable-next-line no-param-reassign
      span.innerText = lang === 'da' ? 'Kopieret' : 'Copied';
      parentBtn?.removeEventListener('click', listener);
      setTimeout(() => {
        // eslint-disable-next-line no-param-reassign
        span.innerText = toCopy;
        parentBtn?.addEventListener('click', listener);
      }, 1200);
    };
    if (parentBtn?.classList.contains('copy-to-clipboard')) {
      parentBtn?.addEventListener('click', listener);
    }
  });
};

export {
  findNav,
  setResponsiveToggle,
  setResponsiveNav,
  setSubNavToggle,
  setCopyToClipboard,
};
