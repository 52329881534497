/* eslint-disable no-param-reassign */
/* eslint-env browser */
import ready from '../components/ready';
import {
  setResponsiveNav,
  setResponsiveToggle,
} from '../components/nav';
import { setMetaThemeColor } from '../components/helpers';
import { setResponsiveTable } from '../components/table';
import { setTabbedContent, isBelowViewportLimit, destroyTabs } from '../components/tabs';
import { setContentToggle } from '../components/content-toggle';

let windowInnerWidth = 0;

const tabbedObjects: ITabbedObject[] = [];

const setTabs = (): void => {
  tabbedObjects.forEach((tabObject: ITabbedObject, key: number): void => {
    const { rendered, domElement } = tabObject;
    if (isBelowViewportLimit(domElement.classList)) {
      if (!rendered) {
        const focus = domElement.getAttribute('data-focus-onload');
        const dataInitial = domElement.getAttribute('data-initial-tab');
        const initial = dataInitial ? parseInt(dataInitial, 10) : 0;
        setTabbedContent(key, domElement, initial, focus !== 'false');
        tabObject.rendered = true;
      }
    } else if (rendered) {
      destroyTabs(domElement);
      tabObject.rendered = false;
    }
  });
};

ready(() => {
  const tabbedContent = document.querySelectorAll('.tabbed-content');
  tabbedContent.forEach((tab) => tabbedObjects.push({
    domElement: tab,
    rendered: false,
  }));
  setTabs();
  setMetaThemeColor();

  const navElements = document.querySelectorAll('.nav:not(.nav--vertical)');
  navElements.forEach((n) => setResponsiveNav(navElements, n));
  setResponsiveToggle(navElements);

  const tables = document.querySelectorAll('.table--wrap-medium-width, .table--wrap-default-width');
  tables.forEach((table) => setResponsiveTable(table));

  const toggles = document.querySelectorAll('.content-toggle__content');
  toggles.forEach((toggle) => {
    setContentToggle(toggle);
  });

  window.addEventListener('load', () => {
    windowInnerWidth = window.innerWidth;
  });

  window.addEventListener('resize', () => {
    if (windowInnerWidth > 0 && window.innerWidth !== windowInnerWidth) {
      windowInnerWidth = window.innerWidth;
      navElements.forEach((n) => setResponsiveNav(navElements, n));
      setTabs();
    }
  });
});
